/* form inputs */
.common-input-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  
  gap: 0px;
}

.common-input-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  
  color: #E7E7E7;
}

.common-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  
  width: 100%;
  height: 48px;
  
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  color: black;
}

/* button */
.common-button-container {
  display: flex;
  flex-direction: row;
  margin: 0px;
  width: auto;
  gap: 10px;
}

.common-button {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
  
  padding: 15px 19px;
  gap: 10px;

  height: 100%;
  aspect-ratio: 10 / 1;

  border-radius: 16px;
  border: none;
}

.common-button-primary {
  background: var(--color-brand);
  &:hover {
    background: var(--color-brand);
  }
}

.common-button-secondary {
  position: relative;
  color: var(--color-brand);
  background: var(--color-pure-white);
  background-clip: padding-box; /* !importanté */
  border: solid 1px transparent; /* !importanté */
  border-radius: 1em;

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -1px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: var(--color-brand);
  }
}

.common-button-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
}

.common-button-text-primary {
  color: var(--color-pure-white)
}

.common-button-text-secondary {
  background: var(--color-brand);
  
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* -- */

.common-hoverable {
  &:hover {
    cursor: pointer;
  }
}


.common-gradient-text-1 {
  background: linear-gradient(107.96deg, var(--color-primary) 70.99%, var(--color-secondary) 75.21%);
  
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.common-gradient-text-2 {
  background: linear-gradient(89.79deg, var(--color-primary) 28.15%, var(--color-secondary) 47.44%);
  
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.common-header-spacer {
  height: 69px;
}

/* image gallery */
.common-imagegallery {
  width: 100%;

  margin: 0 auto auto auto;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: start;
  align-items: start;
  align-content: start;

  gap: 1em 1%;

  overflow-y: auto;
}

.common-imagegallery-item {
  display: grid;
  width: 19%;
}

.common-imagegallery-image {
  grid-column-start: 1;
  grid-row-start: 1;

  width: 100%;
}

.common-imagegallery-image-ellipse {
  grid-column-start: 1;
  grid-row-start: 1;

  width: 25%;
  height: 25%;

  justify-self: end;

  margin: -12px 0px auto auto;

  overflow: visible;

  background-image: url('../assets/icons/circle-x.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  cursor: pointer;
  z-index: 10;
}


/* mobile */
@media (max-width:1000px)  { 
  /* image gallery */
  .common-imagegallery {
    width: 100%;

    margin: 0 auto auto auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: start;
    align-items: start;
    align-content: start;

    gap: 5px 5px;

    overflow-y: auto;
  }
  .common-imagegallery-item {
    display: grid;
    width: 49%;
  }
}