@font-face {
  font-family: 'Inter';
  src: url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
}

:root {
  /* font definition */
  --main-font: "Inter", sans-serif;

  --color-background: #222222;
  --color-landing-background: #0c0c0c;
  --color-left-side-bar-background: #1a1a1a;
  --color-header-background: #131517;
  --color-input-container-background: #3a3a3a;
  --color-inactive-objects: #c1c1c1;
  --color-stroke: #232526;
  --color-popup-windows-background: #222222;

  --color-text: #000000;

  --color-brand: linear-gradient(161.59deg, #4776E6 8.56%, #8E54E9 91.9%);
  --color-blue: rgba(71, 118, 230, 1);
  --color-purple: rgba(142, 84, 233, 1);
  --color-light-gray: rgba(247, 247, 247, 1);
  --color-pure-white: rgba(255, 255, 255, 1);
  --color-dark-gray: rgba(51, 51, 51, 1);
}

/* default font styling for all elements */
* {
  font-family: var(--main-font);
  font-style: normal;

  /*color: var(--color-text);*/
}

/* Links don't have style by default */
a {
  color: inherit;
  text-decoration: none;
}

/* hide/show elements on mobile/desktop */
@media (max-width:1000px)  { 
  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width:1000px)  { 
  .hide-on-desktop {
    display: none !important;
  }
}