/* Global (will mess other things up) */
/* div {
    width: 100%;
    height: 100%;
    margin: auto;
} */

.section-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    /* new */
    width: 100%;
    height: 100%;
    margin: auto;
}

.section-column {
    display: flex;
    flex-direction: column;
    gap: 10px;

    /* new */
    width: 100%;
    height: 100%;
    margin: auto;
}

.root-container {
    position: relative;
    width: 100%;
    height: auto;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    gap: 100px;
}

.root-ellipses {
    position: absolute;
    overflow: visible;

    width: 100vw;
    height: 100%;
    top: 0;

    background-image: url('../../assets/icons/new/hero-ellipses.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
  
    cursor: pointer;
    z-index: -10;
}

.root-netizen {
    position: absolute;
    overflow: hidden;
    height: 42px;
    width: 42px;
    border-radius: 100%;
    z-index: 999;
}

.root-netizen-1 {
    top: 65vh;
    left: -4%;
    background: rgba(255, 217, 0, 1);
    rotate: -10deg;
}

.root-netizen-2 {
    top: 15vh;
    left: 25%;
    background: rgba(188, 255, 255, 1);
    rotate: 10deg;
}

.root-netizen-3 {
    top: 20vh;
    left: 65%;
    background: rgba(184, 33, 255, 1);
    rotate: 10deg;
}

.root-netizen-4 {
    top: 40vh;
    left: 95%;
    background: rgba(30, 132, 240, 1);
    rotate: -10deg;
}

/* Navbar */
.landing-navbar {
    width: 90%;
    justify-content: space-between;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    padding: 10px 0;
    z-index: 1000; /* Ensures navbar stays on top of other content */
}

.landing-navbar-opened {
    width: 90%;
    justify-content: space-between;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    padding: 10px 0;
    z-index: 1000; /* Ensures navbar stays on top of other content */
}

.landing-navbar-name {
    justify-content: start;
    color: black;
    font-size: 24px;
    font-weight: 500;
    line-height: 21.71px;
    letter-spacing: 0.38px;
    text-align: left;   
    gap: 0px; 
}

.landing-navbar-name span:nth-of-type(2) {
    background: var(--color-brand);
    
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.landing-navbar-links {
    padding: 10px 24px;
    background: rgba(242, 242, 242, 0.95);
    border-radius: 99999px;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.landing-navbar-links ul {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    gap: 10px;
}

.landing-navbar-links a {
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    font-weight: 600;
    line-height: 21.71px;
    letter-spacing: 0.38px;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.5);
    &:hover {
        color: black;
    }
}

.landing-navbar-buttons {
    justify-content: end;
    align-items: center;
}

.landing-navbar-buttons div {
    height: 38px;
    justify-content: end;
    align-items: center;
}

.landing-navbar-buttons button {
}

.landing-navbar-buttons span {
    font-size: 12px;
}

/* Hero */
.hero {
    gap: 48px;
    overflow: hidden;
}

.hero-sub-1 {
    width: 90%;
    gap: 50px;
}

.hero-sub-1 img {
    width: 100%;
}

.hero-sub-1-left {
    width: 100%;
    height: auto;
}

.hero-sub-1-right {
    width: 100%;
    align-items: left;
}

.hero-sub-1-right-top {
    gap: 8px;
}

.hero-sub-1-right-top div {
    white-space: nowrap;
    align-items: baseline;

    font-size: 40px;
    font-weight: 700;
    line-height: 48.41px;
    text-align: left;
}

.hero-sub-1-right-top > h1 {
    margin: 0;
}

.hero-sub-1-right-top span:nth-of-type(4) {
    background: var(--color-brand);
    
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-sub-1-right-top-1 {
    gap: 0px;
}

.hero-sub-1-right-top-2 {
    justify-content: left;
    gap: 14px;
}

.hero-subheader {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
}

.hero-sub-1-right-bottom {
    justify-content: left;
    gap: 30px;
}

.hero-sub-2 {
    width: 100%;
    font-weight: 500;
    color: rgba(16, 16, 16, 1);
    gap: 24px;
}

.hero-sub-2 > span {
    margin-left: 5%;
}

.hero-sub-2-bottom {
    justify-content: left;
    overflow: scroll;
}

.hero-video-item {
    position: relative;
    margin: 0;
    height: 300px;
    width: auto;
    aspect-ratio: 9 / 16;
    overflow: hidden;
    background: black;
    border-radius: 16px;
    flex-shrink: 0;
}

.hero-video-item:nth-of-type(1) {
    margin-left: 5%;
}

/* CSS for default (IE) */ 
.hero-video-item video {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    .hero-video-item video {
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        width: 100%;
        height: auto;
        object-fit: contain;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

@supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */ 
    .hero-video-item video {
        position: absolute;
        width: 100%;
        height: auto;
        min-height: 300px;
        object-fit: fill;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

.hero-video-item-logo {
    position: absolute;
    width: 12%;
    height: 100%;
    left: 0;
    top: 5%;
    gap: 10px;
    margin-left: 5px;
}

.hero-video-item-logo h4 {
    color: var(--color-pure-white);
    font-size: 90%;
}

.hero-video-item-icons {
    position: absolute;
    width: 12%;
    height: 100%;
    right: 0;
    top: 10%;
    gap: 10px;
    margin-right: 5px;
}

.hero-video-item-icons img {
    width: 100%;
}

.hero-video-item-icons span {
    color: var(--color-pure-white);
    font-size: 60%;
    font-weight: 500;
}

.hero-video-item-icon-group {
    gap: 0px;
    justify-content: center;
    margin: 0;
    height: auto;
}

.hero-video-item-footer {
    position: absolute;
    width: 100%;
    height: auto;
    margin: 0;
    bottom: 0;
    gap: 5px;
    padding: 5px;
    background: linear-gradient(177.52deg, rgba(0, 0, 0, 0) 2.24%, #000000 103.4%);
}

.hero-video-item-footer h4 {
    color: var(--color-pure-white);
    font-size: 14px;
    font-weight: 600;
    line-height: 0px;
}

.hero-video-item-footer p {
    color: var(--color-pure-white);
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
}

/* Features */
.features {
    width: 90%;
    gap: 10px;
    overflow: hidden;
}

.features-experiences {
    gap: 10px;
}

.features-experiences-header {
    width: 55%;
    align-items: center;
    text-align: center;
    gap: 0px;
}

.features-experiences-header h2 {
    font-size: 45px;
    font-weight: 600;
    line-height: 58px;
    color: black;
}

.features-experiences-header p {
    font-size: 17px;
    font-weight: 400;
    line-height: 24.2px;
    color: rgba(0, 0, 0, 0.7);
}

.features-experiences-content {
    gap: 10px;
}

.features-experiences-content-right {
    min-width: 0;
    width: 100%;
    flex: 5;
    gap: 10px;
    border-radius: 30px;
    overflow: hidden;
}

.features-experiences-content-left {
    position: relative;
    width: 100%;
    flex: 4;
    gap: 10px;
    align-items: center;
}

.features-experiences-content-left-items {
    width: 80%;
    overflow: hidden;
}

.features-experiences-content-left-item {
    padding: 12px 16px;
    gap: 8px;

    background: rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.features-experiences-content-left-item h4 {
    color: black;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}

.features-experiences-content-left-item p {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.features-experiences-content-left-topfade {
    position: absolute;
    top: 0;
    width: 100%;
    height: 20%;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.features-experiences-content-left-bottomfade {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20%;
    background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.features-experiences-content-left-bottomcolor {
    position: absolute;
    bottom: 0;
    height: 4px;
    width: 70%;
    background: linear-gradient(90deg, rgba(39, 125, 203, 0) 8.25%, #1E84F0 69.02%);
}

/* How it works */
.howitworks {
    width: 90%;
    overflow: visible;
}

.howitworks-header {
    width: 55%;
    align-items: center;
    text-align: center;
    gap: 0px;
}

.howitworks-header h2 {
    font-size: 45px;
    font-weight: 600;
    line-height: 58px;
    color: black;
}

.howitworks-content {
    gap: 10px;
}

.howitworks-content-container {
    width: 100%;
    aspect-ratio: 2 / 2;
    background: linear-gradient(90deg, #4776E6 0%, #8E54E9 100%);
    border-radius: 30px;
    box-shadow: 6px 4px 30px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    gap: 0px;
}

.howitworks-content-image {
    width: 100%;
    height: auto;
    margin-bottom: 0px;
}

.howitworks-content-image img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
}

.howitworks-content-footer {
    height: auto;
    min-height: 40%;
    width: 100%;
    background: rgba(255, 255, 255, 1);
    justify-content: bottom;
    margin: 0;
    padding: 24px 8px;
    gap: 0px;
}

.howitworks-content-footer h4 {
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    color: black;
    white-space: nowrap;
}

.howitworks-content-footer p {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.4);
}

/* Plan */
.plans {
    width: 90%;
    margin-top: 70px;
    justify-content: center;
    align-items: center;
    gap: 35px;
    overflow: hidden;
}

.plans-header {
    position: relative;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.plans-header-sticker {
    position: absolute;
    top: -40%;
    width: auto;
    height: auto;
    aspect-ratio: 15 / 1;
    background: linear-gradient(90deg, rgba(71, 118, 230, 0.1) 0%, rgba(142, 84, 233, 0.1) 100%);
    border-radius: 9px;
    z-index: 1;
    gap: 10px;
    padding: 9px;
    align-items: center;
    justify-content: center;
    transform: rotate(-3.08deg);
}

.plans-header-sticker h3 {
    background: var(--color-brand);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
    font-size: 20px;
    margin: 0;
}

.plans-header-sticker-ellipse {
    width: 10px;
    height: 10px;
    background: var(--color-brand);
    border-radius: 100%;
    margin: 0;
}

.plans-header h2 {
    font-size: 45px;
    font-weight: 600;
    line-height: 58px;
}

.plans-header-sub {
    gap: 0;
    justify-content: center;
    align-items: center;
}

.plans-header-sub p {
    margin: 0;
    font-size: 17px;
    font-weight: 400;
    line-height: 24.2px;
    color: rgba(0, 0, 0, 0.7);
}

.plans-content {
    gap: 26px;
    justify-content: center;
    align-items: stretch;
}

.plans-content-container {
    width: 30%;
    height: auto;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 23px 16px;
    border-radius: 30px;
    margin: 0;
}

.plans-content-container h4 {
    font-size: 19px;
    font-weight: 400;
    line-height: 40px;
}

.plans-content-container h3 {
    font-size: 50px;
    font-weight: 600;
    line-height: 10px;
}

.plans-content-container h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
}

.plans-content-header {
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: auto;
    height: auto;
    border-radius: 8px;
}

.plans-content-header h2 {
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 24.2px;
}

.plans-content-features {
    align-items: center;
    justify-content: center;
}

.plans-content-features-item {
    align-items: center;
    justify-content: left;
}

.plans-content-features-item img {
    border-radius: 100%;
    padding: 2%;
}

.plans-content-features-item span {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
}

.plans-content-footer {
    align-items: center;
    justify-content: bottom;
    height: 48px;
}

.plans-content-container-type1 {
    border: 1px solid rgba(0, 0, 0, 0.05);

    .plans-content-header {
        background: linear-gradient(90deg, rgba(71, 118, 230, 0.1) 0%, rgba(142, 84, 233, 0.1) 100%);
    }
    
    .plans-content-header h2 {
        background: var(--color-brand);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h3 {
        color: black;
    }

    h4 {
        color: black;
    }

    h5 {
        color: black;
    }

    .plans-content-features-item img {
        background: linear-gradient(90deg, #4776E6 0%, #8E54E9 100%);
        opacity: 0.4;
    }

    .plans-content-features-item span {
        color: black;
    }
}

.plans-content-container-type2 {
    background: linear-gradient(360deg, #4776E6 0%, #8E54E9 100%);

    .plans-content-header {
        background: rgba(255, 255, 255, 0.1);
    }
    
    .plans-content-header h2 {
        color: var(--color-pure-white)
    }

    h3 {
        color: white;
    }

    h4 {
        color: white;
    }

    h5 {
        color: white;
    }

    .plans-content-features-item img {
        background: rgba(255, 255, 255, 0.1);
    }

    .plans-content-features-item span {
        color: white;
    }
}

/* CTA */
.cta {
    position: relative;
    width: 90%;
    padding: 40px 50px;
    border-radius: 16px;
    clip-path: inset(0% 0% 0% 0% round 16px);
    overflow: hidden;
}

.cta-header {
    width: 100%;
    gap: 0px;
}

.cta-header h2 {
    font-size: 27px;
    font-weight: 600;
    line-height: 33px;
    white-space: nowrap;
    color: white;
}

.cta-header p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    flex: 2;
    min-height: 0;
    color: white;
    margin: 0;
}

.cta-content {
    align-items: end;
    flex: 1;
    min-height: 0;
}

.cta-gradient-1 {
    position: absolute;
    overflow: hidden;

    width: 100%;
    top: 0;
    left: -500px;

    background-image: url('../../assets/icons/new/curve-1.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  
    cursor: pointer;
    z-index: -9;
}

.cta-gradient-2 {
    position: absolute;
    overflow: hidden;

    width: 100%;
    top: 0;
    left: -300px;

    background-image: url('../../assets/icons/new/curve-2.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  
    cursor: pointer;
    z-index: -11;
}

.cta-gradient-3 {
    position: absolute;
    overflow: hidden;

    width: 100%;
    top: 0;
    left: 00px;

    background-image: url('../../assets/icons/new/curve-3.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  
    cursor: pointer;
    z-index: -10;
}

.cta-gradient-4 {
    position: absolute;
    overflow: hidden;

    width: 100%;
    top: 0;
    left: 700px;

    background-image: url('../../assets/icons/new/curve-4.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  
    cursor: pointer;
    z-index: -9;
}

/* Footer */
.footer {
    width: 90%;
    gap: 40px;
    margin-bottom: 10px;
    overflow: hidden;
}

.footer-content {
    
}

.footer-content-name {
    flex: 1;
    align-items: left;
    margin-top: 0;
}

.footer-content-name span {
    justify-content: top;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 21px;
    color: black;
}

.footer-content-links {
    flex: 1;
    margin-top: 0;
    gap: 0px;
}

.footer-content-links-label {
    gap: 9px;
}

.footer-content-links-items {
    gap: 9px;
}

.footer-content-links-label h2 {
    justify-content: top;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 48px;
    color: black;
}

.footer-content-links-items span {
    justify-content: top;
    margin: 0;
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    color: black;
}

.footer-content-social {
    flex: 2;
    margin-top: 0;
}

.footer-content-social h2 {
    justify-content: top;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 48px;
    color: black;
}

.footer-content-social p {
    justify-content: top;
    margin: 0;
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    color: black;
}

.footer-content-social-input {

}

.footer-bottom {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.7);
    padding: 10px 0px;
}

/* mobile */
@media (max-width:1000px)  { 
    .root-container {
        overflow: hidden;
    }

    .root-ellipses {
        width: 500vw;
        height: 200vh;
        top: 0;
    
        background-image: url('../../assets/icons/new/hero-ellipses.svg');
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;
    }

    .root-netizen-1 {
        display: none;
    }
    
    .root-netizen-2 {
        top: 12vh;
        left: 25%;
    }
    
    .root-netizen-3 {
        top: 20vh;
        left: 65%;
    }

    .root-netizen-4 {
        top: 52vh;
        left: 90%;
    }

    .landing-navbar {
        justify-content: space-between;
    }

    .landing-navbar-menu {
        width: auto;
        touch-action: none;
    }

    .landing-navbar-menu img {
        cursor: pointer;
    }

    .landing-navbar-opened {
        height: calc(100vh - 40px);
        width: 100%;
        justify-content: space-between;
        align-items: start;
        margin: 0;
    }
    
    .landing-navbar-opened-top {
        width: 90%;
        height: auto;
        justify-content: space-between;
        align-items: start;
    }

    .landing-navbar-menu-opened {
        position: absolute;
        width: 100%;
        background: red;
        touch-action: none;
    }

    .landing-navbar-links-opened {
        background: none;
        padding: 50px 0;
    }

    .landing-navbar-links-opened ul {
        flex-direction: column;
        gap: 50px;
    }

    .landing-navbar-links-opened li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
    }

    .landing-navbar-links-opened a {
        margin-left: 5%;
        color: black;
        font-size: 40px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0.38px;
    }

    .landing-navbar-buttons-opened {
        height: auto;
        padding: 30px 0;
        justify-content: center;
        align-items: center;
    }

    .landing-navbar-buttons-opened > div {
        height: 60px;
    }

    .landing-navbar-buttons-opened span {
        font-size: 20px;
        padding: 10px;
    }

    .hero-sub-1-right-top div {
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;
        text-align: left;
    }

    .hero-sub-1-right-top-2 {
        gap: 10px;
    }

    .hero-sub-1-right-top-3 {
        margin-right: 10px;
    }

    .hero-sub-1-left {
        display: flex;
        justify-content: center;
    }

    .hero-sub-1-left img {
        width: 90%;
    }

    .hero-sub-1-right {
        align-items: center;
    }

    .hero-sub-1-right-bottom {
        width: 100%;
        margin: 0;
        padding: 0;
        justify-content: center;
    }

    .hero-sub-1-right-bottom > div {
        width: 100%;
        height: auto;
        margin: 0;
        justify-content: center;
    }

    .hero-sub-1-right-bottom .common-button-container span {
        white-space: nowrap;
    }

    .hero-sub-1-right-bottom .common-button-container:nth-of-type(1) button {
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: 0;
    }

    .hero-sub-1-right-bottom .common-button-container:nth-of-type(2) button {
        width: 100%;
        height: auto;
        margin-right: auto;
        margin-left: 0;
    }

    .features-experiences-header {
        width: 80%;
    }

    .features-experiences-header h2 {
        font-size: 35px;
        white-space: nowrap;
    }

    .features-experiences-header p {
        font-size: 15px;
    }

    .features-experiences-content {
        flex-direction: column;
        gap: 40px;
    }

    .features-experiences-content-left-items {
        width: 100%;
        height: 30vh;
    }

    .howitworks {
        width: 100%;
    }

    .howitworks-header h2 {
        font-size: 35px;
        white-space: nowrap;
    }

    .howitworks-content {
        padding-bottom: 40px;
        overflow: scroll;
        justify-content: start;
        gap: 20px;
    }

    .howitworks-content-container {
        min-width: 70%;
    }

    .howitworks-content-container:nth-of-type(1) {
        margin-left: 5%;
    }

    .plans {
        width: 100%;
        overflow: visible;
    }

    .plans-header h2 {
        font-size: 35px;
    }

    .plans-header-sub p {
        font-size: 15px;
    }

    .plans-content {
        overflow: scroll;
        justify-content: start;
        gap: 20px;
    }

    .plans-content-container {
        min-width: 70%;
    }

    .plans-content-container:nth-of-type(1) {
        margin-left: 5%;
    }

    .plans-content-footer > div {
        width: 100%;
    }

    .plans-content-footer button {
        margin: auto;
    }

    .cta {
        flex-direction: column;
        padding: 60px 25px;
        gap: 70px;
    }

    .cta-header {
        gap: 10px;
    }

    .cta-header h2 {
        line-height: 40px;
        white-space: wrap;
    }

    .cta-content {
        align-items: start;
    }

    .cta-gradient-1 {
        width: 100%;
        top: 0;
        left: -200px;
        background-position: right;
    }

    .cta-gradient-2 {
        width: 100%;
        top: 0;
        left: -100px;
        background-position: right;
    }

    .cta-gradient-3 {
        width: 100%;
        top: 0;
        left: 0px;
        background-position: right;
    }

    .cta-gradient-4 {
        width: 100%;
        top: 0;
        left: 200px;
        background-position: left;
    }

    .footer-content {
        flex-direction: column;
        gap: 30px;
    }

    .footer-content-links-items {
        flex-direction: row;
        gap: 10px;
    }

    .footer-content-links-items span {
        font-size: 15px;
        line-height: 16px;
        white-space: nowrap;
    }

    .footer-content-social-input {
        margin-top: 20px;
    }
}